import React from "react";

export default function NotFound() {
  return (
    <>
      <div className="container">
        <div className="row mt-5 pt-5">
          <div className="col-md-12">
            <h1>PAGE NOT FOUND</h1>
          </div>
        </div>
      </div>
    </>
  );
}
