import React from "react";

export default function ProjectsTabHead() {
  return (
    <>
      <div
        className="container-fluid pt-4 pb-5 wow fadeInLeft animated"
        style={{ backgroundColor: "#14254C" }}
      >
        <div className="row">
          <div className="col-md-12 text-center text-white">
            <h1>Sectors We Work in</h1>
          </div>
        </div>
      </div>
    </>
  );
}
