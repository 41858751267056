import React, { useEffect, useState } from "react";
import NavBar from "./Header/Header";

function ScrollTopBtn() {
  const [isVisible, setIsVisible] = useState(false);

  // Handler
  const toggleVisibility = () => {
    if (typeof window !== "undefined" && window.pageYOffset > 600) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", toggleVisibility);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", toggleVisibility);
      }
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <div className="fixed-top wow fadeIn animated">
          <NavBar />
        </div>
      )}
      <div className="scroll-btn-wrapper">
        <button
          className={isVisible ? "visible" : "invisible"}
          type="button"
          onClick={scrollToTop}
        >
          <span className="icon fal fa-angle-up" />
        </button>
      </div>
    </div>
  );
}

export default ScrollTopBtn;
