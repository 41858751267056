import React, { useState } from "react";
// import HeaderTwoTop from "./HeaderTwoTop";
import mainLogo from "../Images/logo.png";
// import SearchIcon from "../Images/magnifying-glass-solid.svg";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const HeaderTwo = () => {
  const [search, setSearch] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <header className="header-wrap header-2">
      {/* <HeaderTwoTop /> */}
      <div className="main-header-wrapper">
        <div
          className="container-fluid align-items-center justify-content-between d-flex"
          style={{ backgroundColor: "white" }}
        >
          <div className="logo wow fadeInLeft animated">
            <Link to="/" onClick={ScrollToTop}>
              <img src={mainLogo} alt="logo" height={70} width={100} />
            </Link>
          </div>
          <div className="main-menu d-none d-lg-block">
            <ul>
              <li>
                <Link to="/" onClick={ScrollToTop}>
                  Home
                </Link>
                {/* <ul className="sub-menu">
                  <li>
                    <Link to="/">homepage 1</Link>
                  </li>
                  <li>
                    <Link to="/homeTwo">homepage 2</Link>
                  </li>
                  <li>
                    <Link to="/homeThree">homepage 3</Link>
                  </li>
                  <li>
                    <Link to="/homeFour">homepage 4</Link>
                  </li>
                </ul> */}
              </li>
              <li>
                <Link to="/about" onClick={ScrollToTop}>
                  about us
                </Link>
              </li>

              <li>
                <Link to="/" onClick={ScrollToTop}>
                  Sectors <i className="fal fa-plus"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/packaging-sector" onClick={ScrollToTop}>
                      Packaging <br />
                      Sector <i className="fal fa-plus"></i>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          FLEXIBLE DIVISION
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          DUPLEX BOXES
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          CORRUGATED BOXES
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/steel-sector" onClick={ScrollToTop}>
                      Steel Sector <i className="fal fa-plus"></i>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Mangaldeep
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Premhari
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          PH Steels
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Dream metplast
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          PH steel Industries
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/retail-sector" onClick={ScrollToTop}>
                      Retail Sector <i className="fal fa-plus"></i>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Mangaldeep
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Prem Paints
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Nourishing Foods
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Prem Shanti
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Prem Pigments
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/construction-sector" onClick={ScrollToTop}>
                      Construction Sector <i className="fal fa-plus"></i>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/" onClick={ScrollToTop}>
                          Ved Kranti Constructions
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* <li>
                <Link to="/news">News</Link>
              </li> */}
              <li>
                <Link to="/clients" onClick={ScrollToTop}>
                  Our Clients{" "}
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={ScrollToTop}>
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-inline-block d-lg-none">
            <div className="mobile-nav-wrap">
              <div id="hamburger" onClick={handleMobileMenu}>
                <i className="fal fa-bars"></i>
              </div>
              <MobileMenu
                mobileMenu={mobileMenu}
                handleMobileMenu={handleMobileMenu}
              />
            </div>
            <div className="overlay"></div>
          </div>
          <div className="right-elements d-none d-xl-flex d-flex align-items-center">
            <div className="search-icon">
              {/* <span
                className="search-btn"
                onClick={handleSearch}
                style={{ cursor: "pointer" }}
              >
                <i className="fas fa-search"></i>
              </span> */}
              <div className={search ? "search-box" : "search-box show"}>
                <form action="#">
                  <input type="text" placeholder="Search" />
                  <button type="submit">
                    <i className="fal fa-search"></i>
                  </button>
                </form>
              </div>
            </div>
            {/* <div className="call-action text-center">
              <a href="tel:+918447247227">
                <span>Contact us</span>
                <p>+918447247227</p>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
