import React from "react";
// import bannerBg from "../assets/img/page-banner.jpg";
import BannerImg from "../Components/Images/T&CImg.jpg";
import PageBanner from "../Components/PageBanner";
import { Helmet } from "react-helmet";

export default function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Prem Industries India Limited</title>
        <meta name="description" content="Terms and Conditions" />
      </Helmet>
      <PageBanner
        title="Terms and Conditions"
        bannerBg={BannerImg}
        currentPage="About"
      />
      <div className="container" style={{ textAlign: "justify" }}>
        <div className="row">
          <div className="col-md-12 mt-3">
            {/* <h2>Heading</h2> */}
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Welcome to Prem Industries India Limited. These Terms & Conditions
              govern your use of our website and any services, products, or
              information provided across our four sectors: Packaging, Retail,
              Steel, and Construction & Infrastructure. By accessing or using
              our website or services, you agree to comply with and be bound by
              these Terms.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Use of Services</h2>
            <ol style={{ fontSize: "17px" }}>
              <li>
                <b>1. Eligibility:</b> By using our services, you confirm that
                you are at least 18 years old and legally capable of entering
                into binding contracts.
              </li>
              <li>
                <b>2. Account Registration:</b> Certain services may require you
                to create an account. You are responsible for maintaining the
                confidentiality of your account details and for all activities
                that occur under your account. You agree to provide accurate,
                current, and complete information during the registration
                process.
              </li>
              <li>
                <b>3. Prohibited Uses:</b> You agree not to misuse our services.
                This includes, but is not limited to, using our website or
                services for any unlawful purpose, to infringe on others'
                rights, or to distribute harmful software or content.
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Product and Service Terms</h2>
            <ol style={{ fontSize: "17px" }}>
              <li>
                <b>1. Packaging Sector:</b> All orders, deliveries, and
                warranties related to packaging products are subject to specific
                terms outlined at the time of purchase or service agreement.
                Prices and availability are subject to change without notice.
              </li>
              <li>
                <b>2. Retail Sector:</b> Retail transactions, including
                purchases and returns, are governed by the terms and conditions
                displayed at the point of sale. Promotions and discounts are
                subject to specific terms and may change without prior notice.
              </li>
              <li>
                <b>3. Steel Sector:</b> Orders for steel products and related
                services are governed by contractual agreements that include
                delivery timelines, payment terms, and warranties. All
                transactions are subject to applicable industry standards and
                regulations.
              </li>
              <li>
                <b>4. Construction & Infrastructure Sector:</b> Projects
                undertaken by our Construction & Infrastructure division are
                governed by detailed contracts that outline project scope,
                timelines, costs, and responsibilities. Any changes to project
                specifications must be agreed upon in writing.
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Intellectual Property</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              All content on our website, including text, images, logos, and
              designs, is the intellectual property of Prem Industries or its
              licensors. Unauthorized use, reproduction, or distribution of this
              content is prohibited.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Limitation of Liability</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              Prem Industries shall not be liable for any direct, indirect,
              incidental, or consequential damages arising from your use of our
              services or products, except where such liability cannot be
              excluded under applicable law.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <h2>Governing Law</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              These Terms are governed by the laws of India. Any disputes
              arising from these Terms or your use of our services shall be
              subject to the exclusive jurisdiction of the courts.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3 mb-4">
            <h2>Changes to Terms</h2>
            <p style={{ textAlign: "justify", fontSize: "17px" }}>
              We reserve the right to modify these Terms at any time. Any
              changes will be posted on our website, and your continued use of
              our services constitutes acceptance of the updated Terms.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
