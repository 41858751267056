import React from "react";

export default function Card(props) {
  return (
    <>
      <div className="card mt-2">
        <a href={props.CardLink}>
          <img src={props.CardImage} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title">{props.CardTitle}</h5>
          </div>
        </a>
      </div>
    </>
  );
}
