import React from "react";
// import { Link } from "react-router-dom";
import img1 from "../../assets/img/project-tab.jpg";
import OurValuesImage from "../Images/OurValues.jpg";
// import img2 from "../../assets/img/project-tab2.jpg";
// import img3 from "../../assets/img/project-tab2.jpg";

function Values() {
  return (
    <section className="our-info-tabs-wrapper mb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="project-tabs-wrapper">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-tab-1"
                  role="tabpanel"
                  aria-labelledby="pills-tab-1"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h1 className="text-center">Our Values</h1>
                        <ul className="mt-5">
                          <li>
                            <span>&bull;</span> We shall embrace new ideas and
                            businesses.
                          </li>

                          <li>
                            <span>&bull;</span> We shall believe in our
                            employees and other shareholders.
                          </li>

                          <li>
                            <span>&bull;</span> We shall stand by our promises
                            and adhere to high
                          </li>
                          <li>
                            <span>&bull;</span> Standards of business
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={OurValuesImage} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Values;
